<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="province">Filter By Province</label>
            <v-select
              id="province"
              v-model="selected.province"
              :options="list.provinces"
              label="province_name"
              placeholder="-- Please Select Province --"
            >
              <template #option="{ province_name }">
                <span>{{ province_name }}</span>
              </template>
              <template #no-options="">
                No Available Provinces
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_municipality">Filter By Municipality</label>
            <v-select
              id="filter_municipality"
              v-model="selected.filter_municipality"
              :options="list.filter_municipalities"
              label="municipality_name"
              :disabled="state.busy"
              placeholder="-- Please Select Municipality --"
            >
              <template #option="{ municipality_name }">
                <span>{{ municipality_name }}</span>
              </template>
              <template #no-options="">
                No Available Municipalities
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="customer_type">Filter By Customer Type</label>
            <v-select
              id="customer_type"
              v-model="selected.customer_type"
              :options="options.customer_types"
              label="text"
              placeholder="-- Please Select Customer Type --"
            >
              <template #option="{ text }">
                <span>{{ text }}</span>
              </template>
              <template #no-options="">
                No Available Customer Types
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter By Live Stock</label>
            <v-select
              id="live_stock"
              v-model="selected.live_stock"
              :options="list.live_stocks"
              label="live_stock_name"
              placeholder="-- Please Select Live Stock --"
            >
              <template #option="{ live_stock_name }">
                <span>{{ live_stock_name }}</span>
              </template>
              <template #no-options="">
                No Available Live Stocks
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="call_status">Filter By Last Call Status</label>
            <v-select
              id="call_status"
              v-model="selected.call_status"
              :options="options.call_statuses"
              label="text"
              placeholder="-- Please Select Call Status --"
            >
              <template #option="{ text }">
                <span>{{ text }}</span>
              </template>
              <template #no-options="">
                No Available Call Statuses
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="unsuccessful_reason">Filter By Last Unsuccessful Reason</label>
            <v-select
              id="unsuccessful_reason"
              v-model="selected.unsuccessful_reason"
              :options="list.unsuccessful_reasons"
              label="reason_name"
              placeholder="-- Please Select Unsuccessful Reason --"
            >
              <template #option="{ reason_name }">
                <span>{{ reason_name }}</span>
              </template>
              <template #no-options="">
                No Available Unsuccessful Reasons
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="disposition_type">Filter By Last Disposition Type</label>
            <v-select
              id="disposition_type"
              v-model="selected.disposition_type"
              :options="list.disposition_types"
              label="type_name"
              placeholder="-- Please Select Disposition Type --"
            >
              <template #option="{ type_name }">
                <span>{{ type_name }}</span>
              </template>
              <template #no-options="">
                No Available Disposition Types
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="disposition">Filter By Last Disposition</label>
            <v-select
              id="disposition"
              v-model="selected.disposition"
              :options="list.dispositions"
              label="disposition_name"
              placeholder="-- Please Select Disposition --"
            >
              <template #option="{ disposition_name }">
                <span>{{ disposition_name }}</span>
              </template>
              <template #no-options="">
                No Available Dispositions
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Lead Tag</label>
            <b-select
              v-model="filter.lead_tag"
              :options="filterLeadTags"
              @change="onTableRefresh"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
            :disabled="tableSettings.totalRows < 1"
            class="mr-1"
            variant="primary"
            @click="state.selected ? onUnSelectAll() : onSelectAll()"
          >
            {{ state.selected ? 'Uncheck Current' : 'Select Current' }}
          </b-button>

          <b-button
            :disabled="selected.campaign_data.length < 1"
            variant="secondary"
            @click="onShowAssignModal"
          >
            Assign Data
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(selected)="row">
          <div class="text-nowrap">
            <div class="form-group">
              <b-form-checkbox
                v-model="selected.campaign_data"
                :value="row.item.id"
              />
            </div>
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-assign-data"
      scrollable
      no-close-on-backdrop
      title="Assign Data"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <div class="col-12 mb-5">
            <ValidationProvider
              #default="{ errors }"
              name="agent"
              vid="agent"
              rules="required"
            >
              <b-form-group>
                <label for="agents">Agent</label>
                <v-select
                  id="agents"
                  v-model="selected.agent"
                  :options="list.agents"
                  :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                  :disabled="state.busy"
                  label="full_name"
                >
                  <template #option="{ full_name }">
                    <strong>{{ full_name }}</strong>
                  </template>
                  <template #no-options="">
                    No Available Agents
                  </template>
                </v-select>
                <div
                  v-if="errors.length > 0"
                  class="invalid-feedback"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </b-form-group>
            </ValidationProvider>
          </div>
        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click="ok()"
        >
          Proceed
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SupervisorCampaignDataService, SharedListService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SupervisorCampaignData',

  middleware: ['auth', 'supervisor'],

  mixins: [formatter],

  metaInfo () {
    return {
      title: 'Campaign Data'
    }
  },

  data () {
    return {
      state: {
        busy: false,
        selected: false
      },
      filter: {
        province: 'all',
        customer_type: 'all',
        disposition_type: 'all',
        disposition: 'all',
        live_stock: 'all',
        call_status: 'all',
        municipality: 'all',
        unsuccessful_reason: 'all',
        lead_tag: 'All'
      },
      list: {
        provinces: [],
        unsuccessful_reasons: [],
        live_stocks: [],
        disposition_types: [],
        dispositions: [],
        filter_municipalities: [],
        lead_tags: []
      },
      options: {
        call_statuses: [
          { text: 'None', value: 'None' },
          { text: 'Connected', value: 'Connected' },
          { text: 'Unsuccessful', value: 'Unsuccessful' }
        ],
        customer_types: [
          { text: 'Raiser', value: 'Raiser' },
          { text: 'Non-Raiser', value: 'Non-Raiser' }
        ]
      },
      selected: {
        agent: null,
        campaign_data: [],
        province: null,
        filter_municipality: null,
        unsuccessful_reason: null,
        customer_type: null,
        disposition_type: null,
        disposition: null,
        live_stock: null,
        call_status: null
      },
      taggingData: {
        agent: null,
        selected: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'selected' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'lead_tag.tag_name', label: 'lead tag' },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'customer_type' },
          { key: 'mobile_number' },
          { key: 'last_agent_name', label: 'last agent' },
          { key: 'last_call_status', label: 'last status' },
          { key: 'last_call', formatter: this.dateTimeFormatterLong },
          { key: 'disposition_type_name', label: 'last disposition type' },
          { key: 'disposition_name', label: 'last disposition' },
          { key: 'unsuccessful_reason_name', label: 'last unsuccessful reason' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      }
    }
  },

  computed: {
    filterLeadTags () {
      return [{ value: 'All', text: 'All' }].concat(this.list.lead_tags)
    }
  },

  watch: {
    'selected.province' (value) {
      this.filter.province = value?.id || 'all'
      this.getMunicipalities(value?.id || null)
      this.onFilterChanged()
    },

    'selected.filter_municipality' (value) {
      this.filter.municipality = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.customer_type' (value) {
      this.filter.customer_type = value?.value || 'all'
      this.onFilterChanged()
    },

    'selected.unsuccessful_reason' (value) {
      this.filter.unsuccessful_reason = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.disposition_type' (value) {
      this.filter.disposition_type = value?.id || 'all'
      this.getDispositions(value?.id || null)
      this.onFilterChanged()
    },

    'selected.disposition' (value) {
      this.filter.disposition = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.live_stock' (value) {
      this.filter.live_stock = value?.id || 'all'
      this.onFilterChanged()
    },

    'selected.call_status' (value) {
      this.filter.call_status = value?.value || 'all'
      this.onFilterChanged()
    },

    'selected.agent' (value) {
      this.taggingData.agent = value?.id || null
    },

    'selected.campaign_data' (value) {
      this.taggingData.selected = value

      if (value.length > 0) {
        this.state.selected = true
      } else {
        this.state.selected = false
      }
    }
  },

  mounted () {
    core.index()
    this.getAgents()
    this.getProvinces()
    this.getUnsuccessfulReasons()
    this.getLiveStocks()
    this.getDispositionTypes()
    this.getFilterLeadTags()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SupervisorCampaignDataService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_province: this.filter.province,
            filter_municipality: this.filter.municipality,
            filter_customer_type: this.filter.customer_type,
            filter_live_stock: this.filter.live_stock,
            filter_call_status: this.filter.call_status,
            filter_unsuccessful_reason: this.filter.unsuccessful_reason,
            filter_disposition_type: this.filter.disposition_type,
            filter_disposition: this.filter.disposition,
            filter_lead_tag: this.filter.lead_tag
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getAgents () {
      await SharedListService.getAgents().then(({ data }) => {
        this.list.agents = data
      })
    },

    async getProvinces () {
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
      })
    },

    async getMunicipalities (province) {
      this.selected.filter_municipality = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.filter_municipalities = data
        })
      } else {
        this.list.filter_municipalities = []
      }
    },

    async getUnsuccessfulReasons () {
      await SharedListService.getUnsuccessfulReasons().then(({ data }) => {
        this.list.unsuccessful_reasons = data
      })
    },

    async getDispositionTypes () {
      this.selected.disposition_type = null
      await SharedListService.getDispositionTypes().then(({ data }) => {
        this.list.disposition_types = data
      })
    },

    async getDispositions (dispositionType) {
      this.selected.disposition = null
      if (dispositionType) {
        await SharedListService.getDispositions(`disposition_type_id=${dispositionType}`).then(({ data }) => {
          this.list.dispositions = data
        })
      } else {
        this.list.dispositions = []
      }
    },

    async getLiveStocks () {
      await SharedListService.getLiveStocks().then(({ data }) => {
        this.list.live_stocks = data
      })
    },

    async getFilterLeadTags () {
      await SharedListService.getLeadTags().then(({ data }) => {
        this.list.lead_tags = data.map(({ id, tag_name }) => ({
          text: tag_name,
          value: id
        }))
      })
    },

    onShowAssignModal () {
      this.selected.agent = null
      this.$bvModal.show('modal-assign-data')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Are you sure you want to assign this campaign data to this current agent?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        SupervisorCampaignDataService.put(this.taggingData).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-assign-data')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              this.$refs.table.refresh()
              this.state.selected = false
              this.selected.campaign_data = []
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    onSelectAll () {
      this.state.selected = true
      this.selected.campaign_data = this.$refs.table.localItems?.map(item => {
        return item.id
      })
    },

    onUnSelectAll () {
      this.state.selected = false
      this.selected.campaign_data = []
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    },

    onTableRefresh () {
      this.$refs.table.refresh()
    }
  }
}
</script>
